import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import axios from 'axios'
import { BlogDetailItem } from '../components/blog/BlogDetailItem'
import { Footer } from '../components/shareable/footer/Footer'

const getUrl = () => {
  if (window.location.origin === 'https://stage.hemset.se') {
    return 'https://adminstage.hemset.se/api'
  }

  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:4000'
  }

  return 'https://admin.hemset.se/api'
}

export default (props) => {
  const [blogItem, setBlogItem] = useState(null)
  const [modalIsOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    async function fetchMyAPI() {
      const id = new URLSearchParams(window.location.search).get('id')
      console.log(id)
      let url = getUrl() + '/getBlogItem?id=' + id + '&secret=surffomieordie'

      const result = await axios.get(url)
      console.log(result)
      if (result.data.success) {
        setBlogItem(result.data.blogItem)
      }
    }

    fetchMyAPI()
  }, [])

  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}

      metaDesc={blogItem ? blogItem.metaDesc : ''}
      headTitle={blogItem ? blogItem.metaTitle : ''}>
      {blogItem && <BlogDetailItem isPreview={true} item={blogItem} />}
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
