import { Flex, ImageCover, Text } from '../primitives'
import ReactHtmlParser from 'react-html-parser'
import React from 'react'
import { Image } from '../shareable/Images/Image'
import styled from 'styled-components'

const H1Title = styled(Text)`
  font-size: 55px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`

export const BlogDetailItem = ({ item, isPreview = false }) => {
  return (
    <Flex width={'100%'} maxWidth={'1000px'} jusifyContent={'center'}>
      <Flex
        flexDirection={'column'}
        maxWidth={'800px'}
        width={'100%'}
        pl={[2, 2, 0]}
        pr={[2, 2, 0]}
        minHeight={'100vh'}>
        <Flex
          style={{ position: 'relative' }}
          height={'500px'}
          jusifyContent={'center'}
          alignItems={'center'}>
          <Flex
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 11
            }}>
            {isPreview ? (
              <ImageCover
                style={{ width: '100%', height: '100%' }}
                src={item.files[0].url}
              />
            ) : (
              <Image src={item.imageFile.childImageSharp.fluid} />
            )}
          </Flex>
        </Flex>
        <Flex flexDirection={'column'}>
          <Flex style={{ zIndex: 12 }}>
            <H1Title style={{ color: 'black' }} as={'h1'}>
              {item.title}
            </H1Title>
          </Flex>
          <Flex
            width={['100%', '100%', '100%']}
            jusifyContent={'center'}
            flexDirection={'column'}>
            {ReactHtmlParser(item.html)}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
